import { useScriptTag } from '@vueuse/core'

export default defineNuxtPlugin((nuxtApp) => {
  const { getConsentForPurpose } = useConsentManager()
  const hasConsent = computed(() =>
    getConsentForPurpose(CONSENT_MANAGER_PURPOSE.ADVERTISING)
  )

  const { load, unload, scriptTag } = useScriptTag(
    'https://pagead2.googlesyndication.com/tag/js/gpt.js',
    init,
    {
      async: true,
      defer: true,
      manual: true,
    }
  )

  /**
   * Adds css helper class skin to body if skin ad is loaded
   */
  function togglePageSkin(isVisible: boolean) {
    if (isVisible) {
      document.body.classList.add('skin')
    } else {
      document.body.style.backgroundColor = ''
      document.body.classList.remove('skin')

      const wallpaper = document.getElementById('GenecyDFPAdWallpaperCont')
      if (wallpaper) {
        wallpaper.remove()
      }
    }
  }

  function init() {
    googletag.cmd.push(() => {
      googletag.pubads().collapseEmptyDivs()
      googletag.pubads().addEventListener('slotOnload', ({ slot }) => {
        if (slot.getAdUnitPath().endsWith('desktop_skin_1x1')) {
          togglePageSkin(true)
        }
      })
      googletag.enableServices()
    })
  }

  async function loadLib() {
    if (hasConsent.value && !scriptTag.value) {
      try {
        await load()
      } catch (e) {
        console.error('Error loading Google Publisher Tag', e)
      }
    }
  }

  nuxtApp.hook('app:mounted', async () => {
    const route = useRoute()
    await loadLib()

    watch(hasConsent, async (isConsentGiven) => {
      if (isConsentGiven) {
        await loadLib()
      }

      if (!isConsentGiven && scriptTag.value) {
        await unload()
      }
    })

    watch(
      () => route.fullPath,
      () => {
        if (hasConsent.value) {
          googletag.destroySlots()
        }
      }
    )
  })

  return {
    provide: {},
  }
})
