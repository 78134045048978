import validate from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/validate.js";
import cms_45global from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/layers/cms/middleware/cms.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/layers/base/middleware/affiliate-and-referrer.global.ts";
import backend_45token_45global from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/layers/base/middleware/backend-token.global.ts";
import consent_45global from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/layers/base/middleware/consent.global.ts";
import utm_45global from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/layers/base/middleware/utm.global.ts";
import widget_45mode_45global from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/layers/base/middleware/widget-mode.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/entradas/dev/2024-11-12_16-02-23_ef37e59/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cms_45global,
  affiliate_45and_45referrer_45global,
  backend_45token_45global,
  consent_45global,
  utm_45global,
  widget_45mode_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}