<template>
  <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ConsentManager />

    <ClientOnly>
      <LazyAdSlot
        v-if="isGooglePublisherTagConsentGiven"
        v-bind="appConfig.adSlots.skin"
        :slot-name="AD_SLOT_NAME.SKIN"
      />
    </ClientOnly>
  </Html>
</template>

<script setup lang="ts">
import { AD_SLOT_NAME } from '@entradas/constants/ads'
import type { Link } from '@unhead/vue'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const route = useRoute()
const { config } = useCms()

if (!config.value) {
  showError({
    statusCode: 404,
    statusMessage: 'configNotFound',
  })
} else {
  const link: Link[] = [
    {
      rel: 'canonical',
      href: `https://${config.value?.defaultDomain}${route.path}`,
    },
  ]

  if (config.value?.favicon) {
    link.push({
      rel: 'icon',
      type: 'image/x-icon',
      href: getImage(config.value, 'favicon', config.value?.name).src as string,
    })
  }

  useHead({
    link,
    titleTemplate(title) {
      return [title, config.value?.name].filter(Boolean).join(' | ')
    },
  })
}

const appConfig = useAppConfig()
const { getConsentForPurpose } = useConsentManager()
const isGooglePublisherTagConsentGiven = computed(() =>
  getConsentForPurpose(CONSENT_MANAGER_PURPOSE.ADVERTISING)
)

defineOptions({
  name: 'AppEntradas',
})
</script>
